<template>
  <div class="row">
    <!-- {{ JSON.stringify(data) }} -->

    <div class="col-lg-8">
      <ProfileStatus :data="data" />
    </div>
    <div class="col-lg-4">
      <!-- <h3>Assigned Staff</h3> -->
      <AssignedStaff :assigned_staff="data.assignedStaff" />
      <!-- <p>{{ data.assignedStaff }}</p> -->
    </div>
    <div class="col-lg-12">
      <!-- <Applications :data="data.applications" /> -->
      <!-- <p>{{ data.applications }}</p> -->
    </div>
    <div class="col-lg-12">
      <OtherDetails :data="data" />
    </div>
  </div>
</template>

<script>
import { FILESURL } from "@/config";
import { BImg } from "bootstrap-vue";
import SchoolCounselorServices from "@/apiServices/SchoolCounselorServices";

import AssignedStaff from "./helper/AssignedStaff.vue";
import Applications from "./helper/Applications.vue";
import ProfileStatus from "./helper/ProfileStatus.vue";
import OtherDetails from "./helper/OtherDetails.vue";
import AgentServices from '@/apiServices/AgentServices';

export default {
  components: {
    BImg,
    AssignedStaff,
    Applications,
    ProfileStatus,
    OtherDetails,
  },
  data() {
    return {
      FILESURL,
      data: {},
    };
  },
  methods: {
    async getHomeData() {
      try {
        const response = await AgentServices.getHomeData();
        if (response.data.status) {
          this.data = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting getHomeData Agent ", err);
      }
    },
  },
  beforeMount() {
    this.getHomeData();
  },
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}
.primary-color {
  color: #00d09c;
}
</style>