<template>
  <div>
    <div>
      <h4 class="dashboard__heading">Applications</h4>
    </div>
    <b-card
      class="card-app-design assigned-staff-card"
    >
    <b-table
      id="StudentsListTableId"
      ref="refStudentsListTable"
      class="position-relative agent-application-table"
      :items="data"
      responsive

    >
      <!-- <template #cell(index)="data">
        {{ studentsList.indexOf(data.item) + 1 }}
      </template> -->
      <!-- <template #cell(unique_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="FILESURL + data.item.user_profile_image"
            />
          </template>
          {{ data.item.user_firstname + " " + data.item.user_lastname }}
        </b-media>
      </template> -->

      <!-- <template #cell(student_applications)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ student_applications_count(data.item.applied_course_ids) }}
          </span>
        </div>
      </template> -->

      <!-- <template #cell(is_converted)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ data.item.is_converted ? data.item.is_converted : "N" }}
          </span>
        </div>
      </template> -->

      <!-- <template #cell(joining_date)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ new Date(data.item.created_at).toLocaleDateString() }}
            <!-- {{ data.item.created_at }} -->
          <!-- </span>
        </div>
      </template>  -->

      <!-- <template #cell(actions)="data">
          <div role="button">
            <b-avatar variant="light-success" size="30">
            <feather-icon
              :id="`student-${data.item.student_user_id}-details`"
              icon="EyeIcon"
              size="15"
              class="text-success"
              @click="
                $router.push({
                  name: 'Student Details',
                  params: { student_user_id: data.item.student_user_id },
                })
              "
            />
            </b-avatar>

            <b-tooltip
              title="Student Details"
              :target="`student-${data.item.student_user_id}-details`"
            />
          </div>
        </template> -->
    </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
.agent-application-table th, .agent-application-table td{
  white-space: nowrap;
}
</style>
